import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqAppBarV2, UqFooterV2, UqLayoutV2, UqNavbarV2, UqLegacyRequestDemoButton } from '@uq-components';
import SEO from '../components/seo';

import * as styles from './unitq-tags-zendesk.module.scss';

export default function UnitqTagsZendesk() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container unitq-tags-zendesk-page">
          <SEO
            title="Zendesk Integration with unitQ"
            description={
              `unitQ's Zendesk integration automatically monitors support tickets
          and user feedback to uncover potential quality issues with your product, in real-time. `
            }
          />
          <Container fluid className="px-0 main">
            <Row noGutters className="header-background justify-content-center">
              <Col className="page-header-wrapper justify-content-center">
                <header className="narrow-page-header">
                  <div className="transparent-page-header-content-wrapper">
                    <h1 className="transparent-header" style={{ margin: 0 }}>
                      unitQ&nbsp;Tags&nbsp;+ Zendesk Support
                    </h1>
                    <div className="transparent-header-text mt-4">
                      Easily add custom tags to label and categorize user feedback and
                      support tickets to streamline issue tracking and reporting.
                    </div>
                  </div>
                </header>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <Container className="content-wrapper">
                  <Row className="mt-5 mb-5 padded-row">
                    <Col className="pt-2">
                      As your businesses or apps become more successful, you’ll
                      obviously receive more and more feedback. And, when quality
                      issues crop up, the volume of incoming feedback reflects both
                      your success and the impact of any particular issue. It then
                      falls to the support or user experience team to manage, triage,
                      research, and gauge the impact of each individual issue. But
                      that becomes more difficult as your apps span versions,
                      platforms, languages, and more. However, unitQ Monitor now
                      offers a custom tagging capability that eases this quality issue
                      tracking, categorization, and monitoring. And, for Zendesk
                      customers, these tags are two-way synchronized to further ease
                      tracking as you work across unitQ Monitor and Zendesk.
                      <br />
                      <br />
                      The new unitQ tags let you add custom tags to label and
                      categorize user feedback that matches a saved search or quality
                      monitor within unitQ Monitor. You can easily create new tags to
                      match your internal terminology, add tags in bulk to existing
                      user feedback, and add tags automatically to new, incoming
                      feedback that matches a particular saved search or quality
                      monitor. Your teams can then use tags to easily filter user
                      feedback based on product lines, internal responsibilities,
                      regions, technologies, stack implications, and more.
                      <br />
                      <br />
                      It’s also faster and easier to drill down into specific quality
                      issues, or report on trends and progress, using tags: just
                      search for the appropriate tags. For Zendesk customers, tags
                      applied in unitQ Monitor are also synced to Zendesk to allow tag
                      filtering from either solution. You can even create targeted
                      bulk replies from Zendesk using tags, or create tags to track
                      unitQ sentiment from within Zendesk.
                      <br />
                      <br />
                      <br />
                      <h4>With unitQ tags, you can: </h4>
                      <br />
                      <ul>
                        <li>
                          Follow quality trends, track issue status, and collaborate
                          on fixes with custom tags that match your internal
                          terminology.
                        </li>
                        <li>
                          Easily monitor, find, and categorize specific quality issues
                          even as they reach across products, versions, and platforms.
                        </li>
                        <li>
                          Stay organized, maintain focus, and keep quality, product,
                          and engineering teams in the loop on quality issues and
                          feedback trends.
                        </li>
                        <li>
                          Maintain persistent tags to ease tracking as you move from
                          unitQ Monitor to Zendesk.
                        </li>
                        <li>
                          Speed searches, ease research, and even target broadcast
                          Zendesk replies related to specific quality issues or user
                          feedback criteria.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
          <Container className="text-center">
            <Row className="mt-5 blue-section">
              <Col lg="1" />
              <Col>
                <h3>See unitQ Tags in Action</h3>
                <br />
                <UqLegacyRequestDemoButton className="UQ-button-revamp large primary">
                  Request Demo
                </UqLegacyRequestDemoButton>
              </Col>
              <Col lg="1" />
            </Row>
          </Container>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
